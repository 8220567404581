export const validatorPositive = (value) => {
  if (value >= 0) {
    return true
  }
  return false
}
///////////////////////////////////////////////// TODO: Write a valitator for siteId ////////////////////
export const validatorSiteID = (validsiteID) => {
  /* eslint-disable no-useless-escape */
   const regExp = /^[a-zA-Z0-9-\s]*$/
  /* eslint-enable no-useless-escape */
  const validatesiteID = regExp.test(validsiteID)
  return validatesiteID
}

export const validatorAzureID = (validAzureID) => {
  /* eslint-disable no-useless-escape */
  const regExp =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/
  /* eslint-enable no-useless-escape */
  const validateAzureID = regExp.test(validAzureID)
  return validateAzureID
}

export const validatorLat = (validLat) => {
  const regExp =
    /^(\+|-)?(?:90(?:(?:\.0{1,17})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,17})?))$/
  const validateLat = regExp.test(validLat)
  return validateLat
}

export const validatorLng = (validLng) => {
  const regExp =
    /^(\+|-)?(?:180(?:(?:\.0{1,17})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,17})?))$/
  const validateLng = regExp.test(validLng)
  return validateLng
}

export const validatorNoSpecial = (noSpecial) => {
  /* eslint-disable no-useless-escape */
  const regExp = /^[a-zA-Z0-9-\s]*$/
  /* eslint-enable no-useless-escape */
  const validNoSpecial = regExp.test(noSpecial)
  return validNoSpecial
}

export const validatorPassword = (password) => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorCreditCard = (creditnum) => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = (val) => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re =
    /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}
