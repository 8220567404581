import { extend } from 'vee-validate'
import {
  required as rule_required,
  email as rule_email,
  min as rule_min,
  max as rule_max,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  length as rule_length
} from 'vee-validate/dist/rules'

// eslint-disable-next-line object-curly-newline
import {
  validatorPositive,
  validatorLng,
  validatorLat,
  validatorUrlValidator,
  validatorPassword,
  validatorCreditCard,
  validatorAzureID,
  validatorSiteID,
  validatorNoSpecial
} from './validators'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', rule_required)

export const email = extend('email', rule_email)

export const min = extend('min', rule_min)

export const max = extend('max', rule_max)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)

export const positive = extend('positive', {
  validate: validatorPositive,
  message: 'Please enter positive number!'
})

export const credit = extend('credit-card', {
  validate: validatorCreditCard,
  message: 'It is not valid credit card!'
})

export const password = extend('password', {
  validate: validatorPassword,
  message:
    'Your {_field_} must contain at least one uppercase, one lowercase, one special character and one digit'
})

export const validAzureID = extend('validAzureID', {
  validate: validatorAzureID,
  message: 'AzureID Group is not in the correct format'
})

export const validSiteID = extend('validSiteID', {
  validate: validatorSiteID,
  message: 'SiteID Group is not in the correct format'
})

export const validLat = extend('validLat', {
  validate: validatorLat,
  message: 'Latitude must be between -90 and 90'
})

export const validLng = extend('validLng', {
  validate: validatorLng,
  message: 'Longitude must be between -180 and 180'
})

export const noSpecial = extend('noSpecial', {
  validate: validatorNoSpecial,
  message: 'Special Characters Are Not Allowed'
})

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: 'URL is invalid'
})
